import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PublicCommentsDetails, { useStyles } from './publicCommentsDetails';
import moment from 'moment';

const PublicComments = props => {
  const classes = useStyles();
  const {
    commentSets,
    isPrinting,
    refs,
    labels,
    isIR,
    isStaff,//Already have validated if isAdmin and CanAdminEdit on component call
    reviewId,
    expanded,
    toggleExpanded,
    indexAttachment,
    openAttachment,
    userOrgs,
    commentDueAt,
    responseDueAt,
  } = props;

  const editComments = (event, commentSet) => {
    event.stopPropagation();
    document.cookie = "commentOrigin=Edit ";
    navigate(
      `${process.env.GATSBY_ORS_BASE_URL}/review/${reviewId}/manage-comments/organization/${commentSet.organization.id}/author/${commentSet.author.id}/${isStaff}`
    );
  };


  useEffect(()=>{
  },[indexAttachment]);

  return (
    <Box>
      <Box my={2}>
        {commentSets.map(commentSet => {
          const isExpanded =
            expanded?.indexOf(commentSet.id) >= 0 || isPrinting;

          // Determine the label of the "submitted after review period" text.
          // "Comment submitted after review period" if only comment was submitted after review period
          // "Response submitted after review period" if only response  was submitted after review period
          // "Comment and Response submitted after review period" if both comment and response was submitted after review period
          const publishedAt = moment(commentSet.publishedAt).unix();
          const lateLabel = ['late', 'lateResponse']
            .filter(p => !!commentSet[p])
            .map(p => {
              if (p === 'late' && (publishedAt >= commentDueAt || commentSet.allCommentsLate)) {
                return 'Comment';
              } else if (p === 'lateResponse' && (publishedAt >= responseDueAt || commentSet.allResponsesLate)) {
                return 'Response';
              }
              return null;
            })
            .filter(Boolean)
            .join(' and ');

          const isLate = commentSet.late || commentSet.lateResponse;
          const isAfterDue = (publishedAt >= commentDueAt || commentSet.allCommentsLate) || (publishedAt >= responseDueAt || commentSet.allResponsesLate);

          return (
            <Accordion
              expanded={isExpanded}
              onChange={(event, isExpanded) => {
                toggleExpanded(commentSet.id, isExpanded)
                if (!isExpanded) {
                  openAttachment(undefined);
                }
              }
              }
              key={commentSet.id}
              style={{ boxShadow: 'none' }}
              className="printSection"
            >
              <AccordionSummary
                className={classes.accordion}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
              >
                <Box display="flex" flex="1" justifyContent="space-between">
                  <Box>{`${commentSet.organization.name}: ${commentSet.author.displayName}`}</Box>

                  <Box display="flex">
                    {isLate && isAfterDue && (
                      <Box display="flex" alignItems="center">
                        <InfoIcon className={classes.infoIcon} />
                        <Typography
                          variant="body2"
                          className={classes.lateLabel}
                        >
                          {lateLabel} submitted after review period
                        </Typography>
                      </Box>
                    )}
                    {isStaff && (
                      <Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={event => editComments(event, commentSet)}
                          className={classes.editBtn}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                    {(!isStaff && commentSet?.canUserAddComments) && (
                      <Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={event => editComments(event, commentSet)}
                          className={classes.editBtn}
                        >
                          Add More
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails className={classes.list}>
                {isExpanded && (
                  <PublicCommentsDetails
                    commentSetId={commentSet.id}
                    commentDueAt = {commentDueAt}
                    responseDueAt = {responseDueAt}
                    allCommentsLate = {commentSet.allCommentsLate}
                    allResponsesLate = {commentSet.allResponsesLate}
                    refs={refs}
                    labels={labels}
                    isIR={isIR}
                    canAdmin={isStaff}/* This covers if a user isAdmin and CanAdminEdit */
                    index={indexAttachment?.index ?? undefined}
                    userOrgs={userOrgs}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

PublicComments.defaultProps = {
  isPrinting: false,
};

PublicComments.propTypes = {
  commentSets: PropTypes.array.isRequired,
  isPrinting: PropTypes.bool,
  refs: PropTypes.any,
  labels: PropTypes.any.isRequired,
  isIR: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
  reviewId: PropTypes.string.isRequired,
  expanded: PropTypes.array.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  indexAttachment: PropTypes.any,
  openAttachment: PropTypes.any,
  userOrgs: PropTypes.any,
  commentDueAt: PropTypes.number,
  responseDueAt: PropTypes.number,
};

export default PublicComments;
