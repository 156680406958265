import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useReactToPrint } from 'react-to-print';
import { navigate } from 'gatsby';
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ArrowForwardIosSharp } from '@material-ui/icons';

import {
  ReviewAlert,
  Map as LocationMap,
  ReviewType,
  Utils,
  ReviewItemLabels,
  InformationRequestItemLabels,
} from '@mvrb-frontend/shared-ui';

import '../../style/page.scss';
import { useReviews, useGetUsers } from '../../hooks';
import { ReviewStatusEnum, OrsRoutes } from '../../constants';
import DetailsOverview from '../../components/details/overview';
import DetailsDocuments from '../../components/details/documents';
import DetailsComments from '../../components/details/comments';
import StatusChangeModal from '../../components/details/modals/statusChangeModal';
import { MenuItems } from '../../constants';
import Page from '../../components/page';
import { isStaffUser, isProponentUser, isLoggedIn, isAdminUser, getUserId } from '../../utils/auth';
import { useReviewCompleted } from '../../hooks/reviewCompleted';
import { useReviewActive } from '../../hooks/reviewActive';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  alert: {
    backgroundColor: '#009B58',
    borderRadius: 0,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subtitle: {
    opacity: 0.5,
  },
  caption: {
    opacity: 0.5,
    fontSize: '11px',
    padding: '15px',
    paddingBottom: 0,
  },
  item: {
    borderRadius: '4px',
    fontSize: '14px',
    color: theme.palette.text.primary,
    '&:focus': {
      backgroundColor: '#F0F2F5',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#1E678F',
        fontWeight: 600,
      },
    },
  },
  menuBar: {
    border: 0,
    boxShadow: 'none',
  },
  alertText: {
    padding: '2px',
  },
  map: {
    width: '100%',
    height: '150px',
  },
  menuItem: {
    color: '#1E678F',
    height: '20px',
    width: '20px',
  },
  selectedItem: {
    color: '#1E678F',
    marginRight: '2px',
    backgroundColor: '#1E678F',
    borderRadius: '4px',
    height: '16px',
    width: '16px',
  },
  navButton: {
    width: '100%',
    '& >span': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  boardProceduresLink: {
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  crown: {
    transition: theme.transitions.create(['height'], {
      duration: theme.transitions.duration.complex,
    }),
    color: theme.palette.common.white,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
  },
  crownExpanded: {
    margin: '0 auto',
  },
  crownSummary: {
    color: theme.palette.common.white,
    marginTop: '10px',
  },
  readMore: {
    color: theme.palette.common.white,
    position: 'absolute',
    bottom: '-20px',
    right: 0,
    fontWeight: 'bolder',
    '&:hover': {
      color: theme.palette.grey.A100,
    },
  },
  readLess: {
    position: 'absolute',
    bottom: '-10px',
    right: '20px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey.A100,
    },
  },
  editHistory:{
    display: 'flex',
    flexWrap: 'wrap',
  },
  accordionSummary:{
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':{
      transform: 'rotate(90deg)'
    }
  },
  expandArea:{
    marginTop: '12px',
    marginbottom: '0px'        
  },
  accordionDetails:{
    width: '100%',
  },
}));

const ReviewDetailsPage = ({ params }) => {
  const isStaff = isStaffUser();
  const userId = getUserId();
  const isAdmin=isAdminUser();
  const [canAdminEdit, setCanAdminEdit] = useState(false);
  const { isFetchingReviews, getReview } = useReviews();
  const { isFetching: isFetchingUsers, getUsers } = useGetUsers();

  const { hasSetCompletedError, setReviewAsCompleted } = useReviewCompleted();
  const { hasSetActiveError, setReviewAsActive } = useReviewActive();
  const classes = useStyles();
  const componentRef = useRef<any>();
  const [details, setDetails] = useState<any>(null);
  const [fieldAudits, setfieldAudits] = useState(null);
  const [isError, setError] = useState(false);
  const [showMarkAsCompletedModal, setShowMarkAsCompletedModal] = useState(
    false,
  );
  const [showMoveBackToActiveModal, setShowMoveBackToActiveModal] = useState(
    false,
  );
  const [isProponent, setProponent] = useState(false);
  const [menuItem, setMenuItem] = useState('#overview');
  const [locationPoint, setLocationPoint] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [labels, setLabels] = useState(ReviewItemLabels);
  const [commentsPeriodOpen, setCommentsPeriodOpen] = useState(false);
  const [responsePeriodOpen, setResponsePeriodOpen] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [crownExpanded, setCrownExpanded] = useState(false);
  
  const getAlertMessage = () => {
    // review is marked as “complete”
    if (details?.status === 'Completed') {
      return <Typography variant="body2">This review is complete.</Typography>;
    }

    // review past proponent due date
    if (!responsePeriodOpen) {
      return (
        <Typography variant="body2">
          The comments and response due date has passed and this item is
          currently under review.
        </Typography>
      );
    }

    // review after comment due date but before proponent response due date
    if (responsePeriodOpen && !commentsPeriodOpen) {
      return (
        <Typography variant="body2">
          The comments due date has passed and this item is currently under
          review.
        </Typography>
      );
    }

    // review before comment due date
    if (commentsPeriodOpen) {
      const daysLeft = moment
        .unix(details?.commentDueAt ? parseFloat(details?.commentDueAt) : 0)
        .startOf('day')
        .diff(moment().startOf('day'), 'days');
      const dueDate = moment
        .unix(details?.commentDueAt ? parseFloat(details.commentDueAt) : 0)
        .format('LL');

      return (
        <Box>
          <Typography variant="body2" className={classes.alertText}>
            {labels.infoBox1}
          </Typography>
          <Typography variant="body2" className={classes.alertText}>
            {labels.infoBox2} {daysLeft} day{`${daysLeft > 1 ? 's' : ''}`} on{' '}
            {dueDate}.
          </Typography>
        </Box>
      );
    }

    return null;
  };

  const printReview = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Review Item',
  });

  const refs = MenuItems.reduce((item, value) => {
    item[value.value] = React.createRef();
    return item;
  }, {});

  const handleScroll = menuItem => {
    refs[menuItem].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const markReviewAsCompleted = () => {
    if (isStaffUser()||(isAdmin && canAdminEdit)) {
      setShowMarkAsCompletedModal(true);
    } else {
      navigate(`${process.env.GATSBY_ORS_BASE_URL}/timeout/:timedout=true`);
    }
  };

  const markReviewAsActive = () => {
    setShowMoveBackToActiveModal(true);
  };

  const setReviewMarkAsCompleted = async () => {
    if (details) {
      await setReviewAsCompleted(details.id);
    }

    if (!hasSetCompletedError) {
      setShowMarkAsCompletedModal(false);
      window.location.reload();
    }
  };

  const moveReviewToActive = async () => {
    if (details) {
      await setReviewAsActive(details.id);
    }

    if (!hasSetActiveError) {
      setShowMoveBackToActiveModal(false);
      window.location.reload();
    }
  };

  const onEditReviewClick = () => {
    window.open(`${OrsRoutes.EditReview.replace(':id', params.id)}`, '_self');
  };

  useEffect(() => {
    (async () => {
      try {

        const response = await getReview(params.id, isAdmin?{canAdmin: true, userId: userId}:{});

        if (typeof response !== 'undefined') {
          const { data } = response;
          if (data?.review) {
            setCanAdminEdit(data.review.canAdminEditReview!==0? true: false)
            
            const { type, organization, proponentUsers } = data.review;

            if (type === ReviewType.InformationRequest && isLoggedIn()) {
              const users = await getUsers(organization?.id);
              setProponent(isProponentUser(users));
            } else {
              setProponent(isProponentUser(proponentUsers));
            }

            setDetails(data.review);
          }

          if (data?.fieldAudit) {
            setfieldAudits(data.fieldAudit);
          }
        }
      } catch (e) {
        setError(true);
      }
    })();
  }, []);

  const parseLocation = () => {
    const location =
      details && Utils.isJsonString(details.location)
        ? JSON.parse(details.location)
        : null;
    if (location && location.latitude && location.longitude) {
      return {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };
    }
    return null;
  };

  useEffect(() => {
    if (details) {
      const { type, commentDueAt, responseDueAt } = details;
      setLabels(
        type === ReviewType.Review
          ? ReviewItemLabels
          : InformationRequestItemLabels,
      );
      if (commentDueAt) {
        setCommentsPeriodOpen(
          moment
            .unix(parseFloat(commentDueAt))
            .startOf('day')
            .diff(moment().startOf('day').format('YYYY-MM-DD'), 'days') >= 0,
        );
      }
      if (responseDueAt) {
        setResponsePeriodOpen(
          moment
            .unix(parseFloat(responseDueAt))
            .startOf('day')
            .diff(moment().startOf('day').format('YYYY-MM-DD'), 'days') >= 0,
        );
      }
      const location = parseLocation();
      if (location) {
        setLocationPoint(location);
      }
    }
  }, [details]);

  //cookie clean up if needed
  useEffect(()=>{
    if(Cookies.get('commentOrigin')){
      Cookies.remove('commentOrigin');
    }
    if(Cookies.get('canAdmin')){
      Cookies.remove('canAdmin');
    }
  },[]);

  return (
    <Page isFetching={false}>
      <Box px={2}>
        {/** Title section */}
        <Box py={3}>
          <Button
            onClick={() => navigate('/reviews')}
            startIcon={<ArrowBackIosIcon />}
          >
            {labels.breadcrumbs}
          </Button>

          {/** Loader */}
          {(isFetchingReviews || isFetchingUsers) && <LinearProgress />}

          {/** Review Details */}
          {details && (
            <Box my={1} mt={3}>
              <Typography data-test={'review-details-name'} variant="h5">
                {details.name}
              </Typography>
              <Typography data-test={'review-details-title'} variant="h2">
                {details.title}
              </Typography>
              <Box my={1}>
                <Typography variant="body2" className={classes.subtitle}>
                  {details.board.name} {/* ( {details.board.abbreviation }) */}
                </Typography>
              </Box>

              <Box>
                <Accordion
                  expanded={crownExpanded}
                  onClick={() => setCrownExpanded(!crownExpanded)}
                  style={{ boxShadow: 'none' }}
                >
                  <AccordionSummary
                    className={classes.alert}
                    classes={{
                      content: clsx(
                        classes.crown,
                        crownExpanded && classes.crownExpanded,
                      ),
                    }}
                  >
                    <Box color={'white'}>
                      <Typography variant={'h4'} style={{ color: 'white' }}>
                        Crown Consultation
                      </Typography>
                      {!crownExpanded && (
                        <Box>
                          <Typography
                            variant={'body1'}
                            className={classes.crownSummary}
                          >
                            The Crown relies on the Board’s process as the
                            primary means to fulfill its duty to consult with
                            Indigenous Peoples and, if appropriate, accommodate
                            potential adverse impacts to asserted or established
                            Aboriginal and/or Treaty rights resulting from any
                            decisions by the federal government, territorial
                            government, or Board in relation to this review
                            item.
                          </Typography>
                          <Typography className={classes.readMore}>
                            Read More
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box my={1.5}>
                      <Typography variant="body1">
                        The Crown relies on the Board’s process as the primary
                        means to fulfill its duty to consult with Indigenous
                        Peoples and, if appropriate, accommodate potential
                        adverse impacts to asserted or established Aboriginal
                        and/or Treaty rights resulting from any decisions by the
                        federal government, territorial government, or Board in
                        relation to this review item.
                      </Typography>
                      <Typography variant="body1">
                        If you identify any potential adverse impacts to your
                        asserted or established Aboriginal and/or Treaty rights
                        with respect to this review item, it is important to
                        provide a submission setting out the details as part of
                        this review. Your submission will inform any preliminary
                        screening determinations, the planning of future steps
                        in the Board’s process, and any other Crown consultation
                        (Board, federal government, or territorial government)
                        steps that may be required. Please explain and provide
                        evidence of the following in your submission: the
                        specific right that may potentially be adversely
                        impacted, the specific potential adverse impacts, and
                        how those potential adverse impacts might be
                        accommodated.
                      </Typography>
                      {crownExpanded && (
                        <Typography className={classes.readLess}>
                          Read Less
                        </Typography>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <Hidden xsDown>
                      <AppBar
                        className={classes.menuBar}
                        color="transparent"
                        position="sticky"
                      >
                        <Box my={2}>
                          <Paper>
                            <Typography
                              variant="body2"
                              className={classes.caption}
                            >
                              Review Sections
                            </Typography>
                            <Box mx={1}>
                              <MenuList>
                                {MenuItems.map(item => {
                                  return (
                                    <MenuItem
                                      key={item.value}
                                      className={classes.item}
                                      onClick={() => {
                                        setMenuItem(item.value);
                                        handleScroll(item.value);
                                      }}
                                      autoFocus={menuItem === item.value}
                                    >
                                      <ListItemText primary={item.label} />
                                      <Hidden smDown>
                                        <ListItemIcon>
                                          <Box pl={5} mt={1}>
                                            {menuItem === item.value ? (
                                              <CheckBoxOutlineBlankIcon
                                                className={classes.selectedItem}
                                                fontSize="small"
                                              />
                                            ) : (
                                              <CheckBoxOutlineBlankIcon
                                                className={classes.menuItem}
                                                fontSize="small"
                                              />
                                            )}
                                          </Box>
                                        </ListItemIcon>
                                      </Hidden>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Box>
                          </Paper>

                          {(isStaff||(isAdmin&&canAdminEdit)) && (
                            <>
                              {
                                <Box my={1.5}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.navButton}
                                    onClick={onEditReviewClick}
                                    endIcon={<EditIcon />}
                                  >
                                    {labels.editItem}
                                  </Button>
                                </Box>
                              }
                              {details.status === ReviewStatusEnum.ACTIVE && (
                                <Box my={1.5}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={classes.navButton}
                                    onClick={markReviewAsCompleted}
                                    endIcon={<CheckIcon />}
                                  >
                                    {labels.completeItem}
                                  </Button>
                                </Box>
                              )}
                              {details.status ===
                                ReviewStatusEnum.COMPLETED && (
                                <Box my={1.5}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={classes.navButton}
                                    onClick={markReviewAsActive}
                                    endIcon={<CheckIcon />}
                                  >
                                    {labels.activeItem}
                                  </Button>
                                </Box>
                              )}
                            </>
                          )}

                          <Box my={1.5}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              className={classes.navButton}
                              onClick={() => {
                                setIsPrinting(true);
                                if (typeof printReview !== 'undefined') {
                                  printReview();
                                }
                              }}
                              endIcon={<PrintIcon />}
                            >
                              {labels.print}
                            </Button>
                          </Box>
                          {details.edit.length>0 && (
                          <Box my={1.5}>
                              <Paper>
                                <Typography
                                  variant="body2"
                                  className={classes.caption}
                                >
                                  Edit History
                                </Typography>
                                  <Box mt={1} className={classes.item}>
                                      {details.edit.map((item, index)=>{
                                          const fixedDate = item.EditedAt.replace('T', ' ').replace('Z', ' ');
                                          const formatedDate = moment(fixedDate).format('MMMM DD, YYYY [at] HH:mm')
                                          return(
                                          <Accordion
                                            key={index}
                                          >
                                            <AccordionSummary
                                              expandIcon={<ArrowForwardIosSharp/>}
                                              style={{margin: '0px'}}
                                              className={classes.accordionSummary}
                                            >
                                              <p style={{paddingBottom: '0px'}}>
                                                On {formatedDate} (MST), the <b>{item.data[0].header} </b> was updated  
                                                because {item.data[0].description}.
                                              </p>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.editHistory}>
                                            {item.data.map((data, index)=>{
                                                if(index!==0){
                                                  return(
                                                    <Box key={index+Math.random()} className={classes.accordionDetails}>
                                                        <p style={{paddingBottom:'0px', marginBottom: '0px'}}>
                                                          <b>{data.header}</b> was updated because  {data.description}.
                                                        </p>
                                                        <br/>
                                                    </Box>
                                                  )
                                                }
                                              })}
                                            </AccordionDetails>
                                          </Accordion>
                                          )
                                      })}
                                  </Box>
                              </Paper>
                          </Box>
                          )}

                          {locationPoint && (
                            <Box my={1.5}>
                              <Paper>
                                <Typography
                                  variant="body2"
                                  className={classes.caption}
                                >
                                  Location
                                </Typography>
                                <Box mt={1} className={classes.map}>
                                  <LocationMap
                                    centre={locationPoint}
                                    pin={locationPoint}
                                    zoom={5}
                                  />
                                </Box>
                              </Paper>
                            </Box>
                          )}
                        </Box>
                      </AppBar>
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <Box my={2}>
                      <Paper>
                        <Box mx={4} my={2} pt={2}>
                          {getAlertMessage() && (
                            <ReviewAlert
                              isVisible={true}
                              severity="info"
                              alertTitle="Info"
                              alertMessage={getAlertMessage()}
                            />
                          )}
                        </Box>

                        <Box mx={4} my={2}>
                          {/** Overview */}
                          <Typography ref={refs[MenuItems[0].value]} />
                          <DetailsOverview
                            fieldAudits={fieldAudits}
                            details={details}
                            labels={labels}
                            canAdminEdit={canAdminEdit}
                          />

                          {/** Documents */}
                          <Typography ref={refs[MenuItems[1].value]} />
                          <DetailsDocuments
                            documents={details.documents}
                            labels={labels}
                          />

                          {/** Comments */}
                          <Typography ref={refs[MenuItems[2].value]} />
                          <DetailsComments
                            reviewId={details.id}
                            commentDueAt={moment.unix(details.commentDueAt).endOf('day').unix()}
                            responseDueAt={moment.unix(details.responseDueAt).endOf('day').unix()}
                            reviewStatus={details.status}
                            labels={labels}
                            reviewType={details.type}
                            commentsPeriodOpen={commentsPeriodOpen}
                            allowSubmitResponses={
                              (isStaff||(isAdmin&&canAdminEdit)) ||
                              (details.status !== ReviewStatusEnum.COMPLETED)
                            }
                            allowSubmitComments={
                              (isStaff||(isAdmin&&canAdminEdit)) ||
                              details.status !== ReviewStatusEnum.COMPLETED
                            }
                            procedureRules={details.board.procedureRules}
                            hyperLinkTitle={details.board.hyperLinkTitle}
                            url={details.board.url}
                            canAdminEdit={canAdminEdit}
                            isProponent={isProponent}
                          />
                          {isPrinting && (
                            <div ref={componentRef} className="printItem">
                              <DetailsOverview
                                details={details}
                                labels={labels}
                                isPrinting={true}
                              />
                              <DetailsDocuments
                                documents={details.documents}
                                labels={labels}
                                isPrinting={true}
                              />
                              <DetailsComments
                                reviewId={details.id}
                                reviewStatus={details.status}
                                labels={labels}
                                isPrinting={true}
                                procedureRules={details?.board?.procedureRules}
                              />
                            </div>
                          )}
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          {/** Mark As Complete Modal */}
          <StatusChangeModal
            title="Mark This Item As Completed"
            text="Are you ready to mark this item as completed?"
            isDialogOpen={showMarkAsCompletedModal}
            onCancel={() => {
              setShowMarkAsCompletedModal(false);
            }}
            onConfirm={setReviewMarkAsCompleted}
          />

          {/** Back to Active Modal */}
          <StatusChangeModal
            title="Move This Item Back to Active"
            text="Are you sure you want to move this item back to active?"
            isDialogOpen={showMoveBackToActiveModal}
            onCancel={() => {
              setShowMoveBackToActiveModal(false);
            }}
            onConfirm={moveReviewToActive}
          />

          {/** Error message */}
          <ReviewAlert
            isVisible={isError}
            severity="error"
            alertTitle="Error"
            alertMessage={
              <Typography variant="body2">Failed to fetch details</Typography>
            }
          />
        </Box>
      </Box>
    </Page>
  );
};

ReviewDetailsPage.propTypes = {
  params: PropTypes.any,
};

export default ReviewDetailsPage;
